import { render, staticRenderFns } from "./list.vue?vue&type=template&id=61425f2d&scoped=true"
import script from "./list.vue?vue&type=script&lang=js"
export * from "./list.vue?vue&type=script&lang=js"
import style0 from "./list.vue?vue&type=style&index=0&id=61425f2d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_prettier@2.8.8_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61425f2d",
  null
  
)

export default component.exports